<div class="p-3">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div class="h3">{{data.title}}</div>    
    <button mat-mini-fab [matDialogClose]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>   
  <div [innerHTML]="data.msg"></div>   
  <div class="d-flex justify-content-end align-items-right p-1">
    <button type="button" mat-raised-button class="bg-custom-1 ml-1 mt-3 text-white" color="secondary" (click)="exit()" *ngIf="data.cancel_button">{{data.cancel_button}}</button>
    <button type="button" mat-raised-button class="bg-custom-1 ml-1 mt-3 text-white" color="primary" (click)="doAction()">{{data.ok_button}}</button>
  </div>  
</div>