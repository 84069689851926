<div class="p-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="h3">Time {{mode}}</div>
  
      <button mat-mini-fab [matDialogClose]="false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <form [formGroup]="form" (ngSubmit)="doAction()">  
      <ng-container  *ngIf="mode=='in'">
        <mat-form-field class="w-100">
          <mat-label>User</mat-label>
          <mat-select formControlName="user_id" required>
            <mat-option *ngFor="let user of users" [value]="user._id">{{user.first_name}} {{user.last_name}}</mat-option>
          </mat-select>          
          <mat-error *ngIf="userIdInput.invalid">{{userIdInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Start Date Time</mat-label>
          <input matInput formControlName="start_date" [ngxMatDatetimePicker]="start_date" placeholder="Choose a date" (focus)="start_date.open()">
          <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>        
          <ngx-mat-datetime-picker #start_date [showSeconds]="true"></ngx-mat-datetime-picker>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="mode=='out'">
        <div class="mt-3 mb-3">User: {{user_name}}</div>
        <mat-form-field class="w-100">
          <mat-label>End Date Time</mat-label>
          <input matInput formControlName="end_date" [ngxMatDatetimePicker]="end_date" placeholder="Choose a date" (focus)="end_date.open()">
          <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>        
          <ngx-mat-datetime-picker #end_date [showSeconds]="true"></ngx-mat-datetime-picker>
        </mat-form-field>      
      </ng-container>
      <div class="d-flex justify-content-end align-items-right p-1">
        <button type="submit" mat-raised-button class="bg-custom-1 ml-1 mt-3 text-white" color="primary">Save</button>
      </div>
    </form>
  </div>
  