<div class="error sec-padding">
   <a [routerLink]="util.getRouterLink()">
      <img src="../../../assets/image/interface/home/logo.png"/>
   </a>
  <div class="container">
     <div class="page404">
        Oops! Error 404
     </div>
     <div class="not-found">
        Page Not Found
     </div>
     <div class="error_description">
       <p>We can't seem to find the page you're looking for.</p>
     </div>
     <button type="button" [routerLink]="util.getRouterLink()" routerLinkActive="active-link" mat-raised-button color="primary" class="btn-project">BACK TO HOME</button>     
  </div>
</div>
