<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<div class="toolbar">
  <a class="p-2" routerLink = "/features" routerLinkActive="active-link" >Features</a>
  <a class="p-2" routerLink = "/how-it-works" routerLinkActive="active-link">How It Works</a>
  <a class="p-2" routerLink = "/support" routerLinkActive="active-link">Support</a>
  <a class="p-2" routerLink = "/pricing" routerLinkActive="active-link">Pricing</a>
  <a class="p-2" routerLink = "/faq" routerLinkActive="active-link">Faq</a>
  <a class="p-2" routerLink = "/blog" routerLinkActive="active-link">Blog</a>  
</div>

