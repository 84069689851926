<div class="footer">
  <div class="footer-list">
    <div class="titles">
      <div class="row justify-content-center">
        <a routerLink = "/features" routerLinkActive="active-link" >Features</a>
        <a routerLink = "/how-it-works" routerLinkActive="active-link">How It Works</a>
        <a routerLink = "/support" routerLinkActive="active-link">Support</a>
        <a routerLink = "/pricing" routerLinkActive="active-link">Pricing</a>
        <a routerLink = "/faq" routerLinkActive="active-link">Faq</a>
        <a routerLink = "/blog" routerLinkActive="active-link">Blog</a>
        <a routerLink = "/contact" routerLinkActive="active-link">Contact</a>
      </div>
    </div>
    <div class="contact-number md text-center">
      <b>Contact us:</b> +1-647-951-8664
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <img src="/assets/image/interface/home/logo.png" alt="Logo"/>
    </div>
  </div>
</div>
